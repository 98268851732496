import { useEffect } from 'react'

import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import './SCORWGModal.css'
import { useTranslation } from 'react-i18next';

const SCORWGModal = (props) => {
  const publication = props.publication;

  useEffect(() => {
    setTimeout(() => document.querySelector('.react-responsive-modal-modal').scrollIntoView({behavior: 'smooth'}, 300), 0);
  }, [])

  const { t } = useTranslation('modal');

  function title() {
    return (
        <div className="pin-container-title">
            <a id="no-dec" href={ publication.url } target="blank">
                { publication.title }
            </a>
        </div>
    );
  }

  function info() {
    return (
      <div className="pin-container-text">
          <span className="pin-container-text-title">
            {t("system")}: </span>
          { publication.system.name }
          <br/>
          <span className="pin-container-text-title">
            {t("indexMagazine")}: </span>
          { publication.indexMagazine.name }
          <br/>
          <span className="pin-container-text-title">
            {t("year")}: </span>
          { publication.year }
          <br/>
          <span className="pin-container-text-title">
             DOI: </span>
          { publication.doi }
      </div>
    );
  }

  function authors() {
    const listItems = publication.authors.map((author) =>
    <div key={author.id} className="author">
      <div className="author-img">
        <img src="modal-icons/user-icon.png" alt="user-icon" />
      </div>
      <div className="author-name">{author.firstName} {author.lastName}</div>
    </div>
    );
    return (
      <div>
        <div className="authors-container">
         <div className="authors-title">{t("authors")}</div>
         <div className="authors-img">
           <img src="modal-icons/author.png" alt="author-imp" />
         </div>
        </div>
        <div className="authors-list">{listItems}</div>
      </div>
    );
  }

  function tags() {
    const listItems = publication.tags.map((tag) =>
      <div key={tag.id} className="tag">{tag.name}</div>
    );
    return (
      <div>
        <div className="tag-title">{t("tags")}:</div>
        <div className="tags-container">{listItems}</div>
      </div>
    );
  }

  function web() {
    return (
      <div className="container-right">
         <a className="content-button" href={ publication.url } target="blank">{t("moreInfo")}</a>
     </div>
    );
  }

  return (
    <Modal open={props.isOpen} onClose={props.closeModal} center>
        <h3>{title()}</h3>
        {authors()}
        {info()}
        {tags()}
        {web()}
    </Modal>
  );
}

export default SCORWGModal;