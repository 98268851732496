import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

const SCORWGCookieConsent = () => {
  const { t } = useTranslation('cookie');
  return (
    <CookieConsent
      location="bottom"
      buttonText={t("button")}
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "white", fontSize: "13px", background: "#4B81E8" }}
    >
      {t("text1")}
      <br/>
      {t("text2")}
    </CookieConsent>
  );
}

export default SCORWGCookieConsent;