import { useState, useEffect, useRef } from 'react';

import 'leaflet/dist/leaflet.css';
import { MapContainer , TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import './SCORWGMap.css'
import './SCORWGMapButtons.css'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { PublicationService } from '../../services/PublicationService.js';
import { MarkerTypeService } from '../../services/MarkerTypeService.js'
import SCORWGModal from '../SCORWGModal/SCORWGModal.jsx';
import SCORWGModalInfo from '../SCORWGModalInfo/SCORWGModalInfo.jsx';
import SCORWGModalAbout from '../SCORWGModalAbout/SCORWGModalAbout.jsx';
import SCORWGModalContact from '../SCORWGModalContact/SCORWGModalContact.jsx';
import SCORWGCookieConsent from '../SCORWGCookieConsent/SCORWGCookieConsent.jsx';

import { MarkerType } from '../../models/MarkerTypeModel.js';

import { useTranslation } from 'react-i18next';

L.Icon.Default.imagePath='leaflet-icons/';

const corner1 = L.latLng(-90, -170)
const corner2 = L.latLng(90, 190)
const bounds = L.latLngBounds(corner1, corner2)

const SCORWGMap = (props) => {
  const [markers, setMarkers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPublication, setSelectedPublication] = useState(null);
  const [isSatelliteMap, setIsSatelliteMap] = useState(true);
  const [infoIsOpen, setInfoIsOpen] = useState(false);
  const [totalMarkers, setTotalMarkers] = useState(0);
  const [markerTypesIcons, setMarkerTypesIcons] = useState();
  const [markerTypes, setMarkerTypes] = useState([]);
  const [markerTypesCounter, setMarkerTypesCounter] = useState([]);
  const initialRender = useRef(true);

  const { t } = useTranslation('common');

  function openModal(publication) {
    setSelectedPublication(publication);
    setModalIsOpen(true);
  }
  
  function closeModal(){
    setModalIsOpen(false);
  }

  function openInfo() {
    setInfoIsOpen(true);
  }
  
  function closeInfo(){
    setInfoIsOpen(false);
  }


  useEffect(() => {
    async function fetchMarkerTypes() {
      let response = await MarkerTypeService.getMarkerTypes();
      let tempMarkerTypes = response;
      tempMarkerTypes.push(new MarkerType({id: 0, name: "Default", marker_url: "leaflet-icons/marker-icon.png"}));
      setMarkerTypes(tempMarkerTypes);
      let markersTypesObj = {}
      response.forEach(markerType => {
        markersTypesObj[markerType.id] = new L.Icon({
          iconUrl: markerType.markerUrl,
          iconSize: [25, 25],
        });
      });
      setMarkerTypesIcons(markersTypesObj);
    }
    fetchMarkerTypes();
  }, []);

  useEffect(() => {
    async function fetchPublications() {
      let publications = await PublicationService.getPublications();
      let markersTypesCounter = {}
      setTotalMarkers(publications.length);
      setMarkers(
        publications.map(publication => {
          let publicationMarkerTypeId = publication.markerType === null ? 0 : publication.markerType;
          let currentCounter = markersTypesCounter[publicationMarkerTypeId];
          markersTypesCounter[publicationMarkerTypeId] = currentCounter !== undefined ? currentCounter + 1 : 1;
          return <Marker 
            key={publication.id} 
            position={ [publication.latitude, publication.longitude] } 
            {...(publication.markerType == null ? {} : {icon: markerTypesIcons[publication.markerType.toString()]})}
          >
            <Popup id="popup" >
              <div  className="marker-title">{ publication.title }</div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
                <button className="button" onClick={ () => openModal(publication) }>
                  {t("moreInfo")}
                </button>
              </div>
            </Popup>
          </Marker>
        })
      )
      setMarkerTypesCounter(markersTypesCounter);
    }
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      fetchPublications();
    }
  }, [markerTypesIcons, t])

  const apiKey = process.env.API_KEY_GOOGLE;

  return (
    <div id="scorwg-map">
      <SCORWGCookieConsent/>
      {modalIsOpen && 
        <div style={{position: 'absolute', zIndex: 2}}>
          <SCORWGModal
            isOpen={ modalIsOpen }
            closeModal={ closeModal }
            publication={ selectedPublication }
          />
        </div>
      }

      {infoIsOpen && 
        <div style={{position: 'absolute', zIndex: 3}}>
          <SCORWGModalInfo
            isOpen={ infoIsOpen }
            closeModal={ closeInfo }
            length={ totalMarkers }
            markerTypes={ markerTypes }
            markerTypesCounter={ markerTypesCounter }
          />
        </div>
      }


      {props.aboutIsOpen && 
        <div style={{position: 'absolute', zIndex: 4}}>
          <SCORWGModalAbout
            isOpen={props.aboutIsOpen }
            closeModal={ props.closeAbout }
          />
        </div>
      }

      {props.contactIsOpen && 
        <div style={{position: 'absolute', zIndex:5}}>
          <SCORWGModalContact
            isOpen={props.contactIsOpen }
            closeModal={ props.closeContact }
          />
        </div>
      }



      <div className="radio-toggle">
        <button onClick={ () => setIsSatelliteMap(true) } style={{ color: !isSatelliteMap ? 'darkgrey' : 'black' }}>{t("map-controls.satellite")}</button>
        <button onClick={ () => setIsSatelliteMap(false) } style={{ color: isSatelliteMap ? 'darkgrey' : 'black' }}>{t("map-controls.map")}</button>
      </div>

      <div className="info-toggles">
          <button className="button_info" type="button" onClick={ openInfo }>
            i
          </button>
      </div>

      <MapContainer 
        className="markercluster-map"
        center={props.position} 
        zoom={3}
        minZoom={2} 
        scrollWheelZoom={true} 
        style={ {width: '100%', height: '100%', position: 'absolute', zIndex: 0} }
        maxBoundsViscosity={1.0}
        maxBounds={bounds}
        tap={false}
        whenCreated={(map) => { setTimeout(() => map.invalidateSize(), 1000); }}
      >

        {
          !isSatelliteMap 
          ? 
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          :
          <ReactLeafletGoogleLayer apiKey={apiKey} type={'satellite'} />
        }

        <MarkerClusterGroup >
          { markers }
        </MarkerClusterGroup>

      </MapContainer>

    </div>
  );
}

export default SCORWGMap;
