import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SCORWGNavbar.css'
import { useTranslation } from 'react-i18next';

const SCORWGNavbar = (props) => {
    const { t, i18n } = useTranslation('common');
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    return (
        <div id="scorwg-navbar">
            <Navbar className="navbar-container" variant="light">
                <img
                    src="/logo.png"
                    width="80"
                    height="80"
                    className="d-inline-block align-top img-margin"
                    alt="SCORWG logo"
                />
                <div href="#home">
                    <div className="navbar-title b">{t("title")}</div>
                    <div className="navbar-title">{t("subtitle.part1")}</div>
                    <div className="navbar-title">{t("subtitle.part2")}</div>
                </div>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link className="nav-link" onClick={props.openAbout} >{t("menu.aboutUs")}</Nav.Link >
                        <Nav.Link  className="nav-link" onClick={props.openContact } >{t("menu.contact")}</Nav.Link >
                        <Nav.Link  className="nav-link"  onClick={() => changeLanguage('en')} >English</Nav.Link >
                        <Nav.Link  className="nav-link"  onClick={() => changeLanguage('es')} >Spanish</Nav.Link >
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className="abajo"></div>
        </div>
    );
};

export default SCORWGNavbar;