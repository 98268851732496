import { useEffect } from 'react'

import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import './SCORWGModalInfo.css'
import { useTranslation } from 'react-i18next';

const SCORWGModalInfo = (props) => {
  const length = props.length;

  useEffect(() => {
    setTimeout(() => document.querySelector('.react-responsive-modal-modal').scrollIntoView({behavior: 'smooth'}, 300), 0);
  }, [])
  
  const { t } = useTranslation(['legend','common']);

  function mapLegend() {
    return props.markerTypes.map(markerType => 
      <div key={ markerType.id }>
        <img className="legend-img" src={ markerType.markerUrl } alt={ markerType.name } />
        : { markerType.name } ({ props.markerTypesCounter[markerType.id]||0  } {t("total")})
      </div>
    );
  }

  return (
    <Modal open={props.isOpen} onClose={props.closeModal} center >
      <br />
      <h3>{t("title")}</h3>
      {t("number-of-publications.part1")} <b> { length } </b> {t("number-of-publications.part2")} <br/> <br/>
      { mapLegend() }
      <br/>
      {t("footer.part1", { ns: 'common' })} 
      <a href="https://compsust.utec.edu.pe/" target="blank"> COMPSUST, UTEC </a>
      {t("footer.part2", { ns: 'common' })} 
    </Modal>
  );
}

export default SCORWGModalInfo;