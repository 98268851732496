import SCORWGMap from "../components/SCORWGMap/SCORWGMap.jsx"
import SCORWGFooter from '../components/SCORWGFooter/SCORWGFooter.jsx';
import SCORWGNavBar from '../components/SCORWGNavBar/SCORWGNavbar'
import { useState } from 'react';

const MapView = (props) => {
  const [aboutIsOpen, setAboutIsOpen] = useState(false);
  const [contactIsOpen, setContactIsOpen] = useState(false);


  function openAbout() {
    setAboutIsOpen(true);
  }
  
  function closeAbout(){
    setAboutIsOpen(false);
  }

  function openContact() {
    setContactIsOpen(true);
  }
  
  function closeContact(){
    setContactIsOpen(false);
  }


  return (
    <>
      <SCORWGNavBar
        aboutIsOpen={aboutIsOpen}
        contactIsOpen={contactIsOpen}

        openAbout={openAbout}
        closeAbout={closeAbout}
        
        openContact={openContact}
        closeContact= {closeContact}
      >
      </SCORWGNavBar>

      <SCORWGMap
        position={props.position}

        aboutIsOpen={ aboutIsOpen}
        contactIsOpen={ contactIsOpen}

        openAbout={ openAbout}
        closeAbout={ closeAbout}

        openContact={ openContact}
        closeContact= { closeContact}
      />
      <SCORWGFooter />
    </>
  );
}

export default MapView;