import { useEffect } from 'react'

import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import './SCORWGModalContact.css'
import { useTranslation } from 'react-i18next';

const SCORWGModalContact = (props) => {

  useEffect(() => {
    setTimeout(() => document.querySelector('.react-responsive-modal-modal').scrollIntoView({behavior: 'smooth'}, 300), 0);
  }, [])

  const { t } = useTranslation('contact-us');

  return (
    <Modal open={props.isOpen} onClose={props.closeModal} center >
      <br />
      <h3>{t('title')}</h3>
      <p>{t('description.part1')} <a href="mailto:imontes@igp.gob.pe">imontes@igp.gob.pe</a></p>
      <p>{t('description.part2')} <a href="https://scor-int.org" target="_blank" rel="noreferrer">scor-int.org</a></p>
      <p>{t('description.part3')} <a href="https://www.igp.gob.pe/programas-de-investigacion/ciencias-de-la-atmosfera-e-hidrosfera/proyectos/SCOR" target="_blank" rel="noreferrer">SCOR WORKING GROUP I55</a></p>
      </Modal>
  );
}

export default SCORWGModalContact;
