import MapView from "./views/MapView";
import "./App.scss";
import React, { Suspense } from "react";

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <MapView position={[-6, -20]} />
    </Suspense>
  );
}

export default App;
