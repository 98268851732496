import React from 'react'
import './SCORWGFooter.css'
import { useTranslation } from 'react-i18next';

export default function SCORWGFooter() {
  const { t } = useTranslation('common');

  return (
    <div id="scorwg-footer">
      <p>
        {t("footer.part1")}
        <a href="https://compsust.utec.edu.pe/" target="blank"> COMPSUST, UTEC </a>
        {t("footer.part2")}
      </p>
    </div>
  )
}
