import { Tag } from '../models/TagModel.js';
import { Author } from '../models/AuthorModel.js';
import { System } from '../models/SystemModel.js';
import { IndexMagazine } from '../models/IndexMagazineModel.js';

export class Publication {
  constructor(newPublication) {
    this.id = newPublication.id;
    this.title = newPublication.title;
    this.year = newPublication.year;
    this.latitude = newPublication.latitude;
    this.longitude = newPublication.longitude;
    this.doi = newPublication.doi;
    this.url = newPublication.url;
    this.tags = newPublication.tags.map(tag => new Tag(tag));
    this.authors = newPublication.author.map(author => new Author(author));
    this.system = new System(newPublication.system);
    this.indexMagazine = new IndexMagazine(newPublication.index_magazine);
    this.markerType = newPublication.marker_type;
  }
}