import { useEffect } from 'react'

import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import './SCORWGModalAbout.css'
import { useTranslation } from 'react-i18next';

const SCORWGModalAbout = (props) => {

  useEffect(() => {
    setTimeout(() => document.querySelector('.react-responsive-modal-modal').scrollIntoView({behavior: 'smooth'}, 300), 0);
  }, [])

  const { t } = useTranslation('about-us');

  return (
    <Modal open={props.isOpen} onClose={props.closeModal} center >
      <br />
      <h3>{t('title')}</h3>
      <p>{t('description.part1')}</p>
      <p>{t('description.part2')}</p>
      <p>{t('description.part3')}</p>
      </Modal>
  );
}

export default SCORWGModalAbout;
